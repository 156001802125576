"use client";

import React from "react";
import Typography from "_components/Typography";
import dynamic from "next/dynamic";

import loading from "./loading.json";

const Lottie = dynamic(() => import("lottie-react"), {
  ssr: true,
});

const ChartLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Lottie
        animationData={loading}
        loop={true}
        // autoplay={true}
        style={{
          width: "230px",
          height: "130px",
        }}
      />
      <Typography
        variant="caption.100"
        color="text_15"
        style={{ position: "relative", bottom: "40px" }}
      >
        데이터를 가져오는 중이에요
      </Typography>
    </div>
  );
};

export default ChartLoading;
