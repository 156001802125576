/* eslint-disable no-unused-vars */
import Loading from "_components/Loading";
import Typography from "_components/Typography";
import { useDescribeAlarms } from "_libs/swr/dashboard/dashboard";
import styled from "styled-components";

const mockAlarms = {
  data: {
    MetricAlarms: [
      {
        AlarmName: "CPU 사용률 경고",
        AlarmDescription: "CPU 사용률이 90%를 초과했습니다.",
        severity: "high",
      },
      {
        AlarmName: "메모리 부족",
        AlarmDescription: "가용 메모리가 10% 미만입니다.",
        severity: "high",
      },
      {
        AlarmName: "디스크 공간 부족",
        AlarmDescription: "디스크 사용량이 85%를 초과했습니다.",
        severity: "medium",
      },
      {
        AlarmName: "네트워크 지연",
        AlarmDescription: "평균 응답 시간이 2초를 초과했습니다.",
        severity: "medium",
      },
      {
        AlarmName: "데이터베이스 연결 실패",
        AlarmDescription:
          "데이터베이스 연결 시도 실패가 5회 이상 발생했습니다.",
        severity: "high",
      },
      {
        AlarmName: "API 오류율 증가",
        AlarmDescription: "API 오류율이 5%를 초과했습니다.",
        severity: "medium",
      },
      {
        AlarmName: "보안 그룹 변경",
        AlarmDescription: "보안 그룹 설정이 변경되었습니다.",
        severity: "low",
      },
      {
        AlarmName: "백업 실패",
        AlarmDescription: "정기 백업 작업이 실패했습니다.",
        severity: "high",
      },
      {
        AlarmName: "SSL 인증서 만료 임박",
        AlarmDescription: "SSL 인증서가 30일 이내에 만료됩니다.",
        severity: "low",
      },
      {
        AlarmName: "로그인 시도 실패",
        AlarmDescription: "동일 IP에서 다수의 로그인 실패가 감지되었습니다.",
        severity: "medium",
      },
      {
        AlarmName: "서버 재시작",
        AlarmDescription: "예기치 않은 서버 재시작이 발생했습니다.",
        severity: "high",
      },
      {
        AlarmName: "트래픽 급증",
        AlarmDescription: "비정상적인 트래픽 증가가 감지되었습니다.",
        severity: "medium",
      },
    ],
  },
};

const IssueLogsCard = () => {
  // 잠시 useDescribeAlarms 훅 대신 목업 데이터 사용
  // const {
  //   data: alarms,
  //   mutate: mutateAlarms,
  //   isLoading: isLoadingAlarms,
  //   isError: isErrorAlarms,
  // }: any = useDescribeAlarms();

  const alarms = mockAlarms;
  const isLoadingAlarms = false;

  if (isLoadingAlarms) {
    return <Loading.Chart />;
  }

  return (
    <S.Container>
      <S.Title variant="subtitle.200_sb" color="text_5">
        Issue Logs
      </S.Title>
      {!alarms?.data?.MetricAlarms || alarms.data.MetricAlarms.length === 0 ? (
        <S.EmptyContainer>
          <S.Message variant="body.300" color="text_5">
            현재 표시할 이슈 로그가 없습니다.
          </S.Message>
        </S.EmptyContainer>
      ) : (
        <S.IssueList>
          {alarms.data.MetricAlarms.map((issue, index) => (
            <S.IssueItem key={index} severity={issue.severity}>
              <S.IssueTitle variant="body.300" color="text_5">
                {issue.AlarmName}
              </S.IssueTitle>
              <S.IssueDescription variant="caption.200" color="text_20">
                {issue.AlarmDescription}
              </S.IssueDescription>
            </S.IssueItem>
          ))}
        </S.IssueList>
      )}
    </S.Container>
  );
};

export default IssueLogsCard;

const S = {
  Message: styled(Typography)({
    textAlign: "center",
    padding: "20px",
  }),
  EmptyContainer: styled("div")({
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  }),
  Container: styled("div")({
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),
  Title: styled(Typography)({
    marginBottom: "16px",
  }),
  IssueList: styled("div")({
    overflowY: "auto",
    flexGrow: 1,

    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E0E0E0",
      borderRadius: "3px",
    },
  }),
  IssueItem: styled("div")<{ severity: string }>(({ severity }) => ({
    backgroundColor: severity === "critical" ? "wthie" : "wthie",
    borderRadius: "4px",
    marginBottom: "16px",
    position: "relative",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    height: "38px",

    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      width: "2px",
      backgroundColor:
        severity === "high" || severity === "critical"
          ? "#FF0000" // 빨간색
          : severity === "medium"
            ? "#FFA000" // 오렌지색
            : severity === "low"
              ? "#2196F3"
              : "#FF0000", // 우선은 전체 빨간색으로 표시
    },
  })),
  IssueTitle: styled(Typography)({
    marginBottom: "4px",
  }),
  IssueDescription: styled(Typography)({}),
};
