import { Variants } from "_types/variants";

import { PropDef } from "./prop-def";

const variants = [
  "headline.100",
  "headline.200",
  "headline.300",
  "headline.400",
  "title.100",
  "title.101",
  "title.100_sb",
  "title.200",
  "title.200_sb",
  "subtitle.100",
  "subtitle.100_sb",
  "subtitle.101",
  "subtitle.200",
  "subtitle.200_sb",
  "subtitle.201",
  "subtitle.202_sb",
  "body.100",
  "body.100_sb",
  "body.200",
  "body.200_sb",
  "body.201",
  "body.300",
  "body.300_md",
  "body.300_sb",
  "body.301",
  "caption.100",
  "caption.100_md",
  "caption.100_sb",
  "caption.200",
  "caption.200_sb",
  "caption.300",
  "caption.300_sb",
  "gnb_on",
  "gnb_off",
  "btn",
] as const;

const variantPropDef = {
  variant: { type: "enum", values: variants, default: "body.100" },
} satisfies {
  variant: PropDef<Variants>;
};

export { variantPropDef };
