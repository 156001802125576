import React from "react";
import Tab from "_components/Tab";

interface TabItem {
  label: string;
  active: "on" | "off";
  onClick: () => void;
}

interface TabsProps {
  items: TabItem[];
}

const Tabs: React.FC<TabsProps> = ({ items }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingLeft: "20px",
        paddingTop: "20px",
      }}
    >
      {items.map((item, index) => (
        <Tab.Line
          key={index}
          active={item.active}
          onClick={item.onClick}
          showBadge
        >
          {item.label}
        </Tab.Line>
      ))}
    </div>
  );
};

export default Tabs;
