import ChartLoading from "./ChartLoading";
import PlwidaLoading from "./PlwidaLoading";

const Loading = () => {
  return;
};

Loading.Plwida = PlwidaLoading;
Loading.Chart = ChartLoading;

export default Loading;
