import Loading from "_components/Loading";
import Typography from "_components/Typography";
import { getCostAndUsage } from "_libs/swr/dashboard/dashboard";
import styled from "styled-components";

const BillingCard = () => {
  // 목업 데이터 생성
  const mockCostAndUsage = {
    accrueCost: "5135.56",
    results: [
      {
        ResultsByTime: [
          {
            TimePeriod: {
              Start: "2023-05-01",
            },
            Total: {
              UnblendedCost: {
                Amount: "1234.56",
              },
            },
          },
          {
            TimePeriod: {
              Start: "2023-05-02",
            },
            Total: {
              UnblendedCost: {
                Amount: "45.67",
              },
            },
          },
          // 이전 달 데이터
          {
            TimePeriod: {
              Start: "2023-04-01",
            },
            Total: {
              UnblendedCost: {
                Amount: "3456.78",
              },
            },
          },
        ],
      },
    ],
  };

  // getCostAndUsage 훅 대신 목업 데이터 사용
  const costAndUsage = mockCostAndUsage;
  const isLoadingCostAndUsage = false;

  // 데이터 로딩 중일 때 처리
  if (isLoadingCostAndUsage) {
    return <Loading.Chart />;
  }

  // 누적 비용
  const accrueCost = costAndUsage?.accrueCost || "0";

  // 현재 기간의 비용 (가장 최근 데이터)
  const currentPeriodCost =
    costAndUsage?.results[0]?.ResultsByTime.slice(-1)[0]?.Total?.UnblendedCost
      .Amount || "0";

  // 전일 비용 (현재 기간의 바로 이전 데이터)
  const previousDayCost =
    costAndUsage?.results[0]?.ResultsByTime.slice(-2)[0]?.Total?.UnblendedCost
      .Amount || "0";

  // 이전 달 비용
  const previousMonthCost =
    costAndUsage?.results[0]?.ResultsByTime.find((item) =>
      item.TimePeriod.Start.startsWith("2023-04"),
    )?.Total?.UnblendedCost.Amount || "0";

  return (
    <S.Container>
      <S.Title variant="subtitle.200_sb" color="text_5">
        Billing
      </S.Title>
      <S.CurrentPeriod>
        <S.PeriodInfo>
          <S.PeriodTitle variant="body.300_sb" color="white">
            이번달 누적 비용
          </S.PeriodTitle>
          <S.PeriodDate variant="caption.200" color="white">
            {costAndUsage?.results &&
              costAndUsage?.results[0].ResultsByTime[0].TimePeriod.Start}{" "}
            ~ Today
          </S.PeriodDate>
        </S.PeriodInfo>
        <S.Amount>${parseFloat(accrueCost).toLocaleString()}</S.Amount>
      </S.CurrentPeriod>
      <S.BillingList>
        <S.BillingItem>
          <S.ItemTitle variant="body.300" color="text_10">
            예상 비용
          </S.ItemTitle>
          <S.ItemAmount variant="body.300" color="text_10">
            ${parseFloat(currentPeriodCost).toLocaleString()}
          </S.ItemAmount>
        </S.BillingItem>
        <S.Divider />
        <S.BillingItem>
          <S.ItemTitle variant="body.300" color="text_10">
            전일 비용
          </S.ItemTitle>
          <S.ItemAmount variant="body.300" color="text_10">
            ${parseFloat(previousDayCost).toLocaleString()}
          </S.ItemAmount>
        </S.BillingItem>
        <S.Divider />
        <S.BillingItem>
          <S.ItemTitle variant="body.300" color="text_10">
            전월 비용
          </S.ItemTitle>
          <S.ItemAmount variant="body.300" color="text_10">
            ${parseFloat(previousMonthCost).toLocaleString()}
          </S.ItemAmount>
        </S.BillingItem>
      </S.BillingList>
    </S.Container>
  );
};

export default BillingCard;

const S = {
  Container: styled("div")({
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),
  Title: styled(Typography)({
    marginBottom: "14px",
  }),
  CurrentPeriod: styled("div")({
    backgroundColor: "#333",
    color: "white",
    padding: "16px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  PeriodInfo: styled("div")({
    display: "flex",
    flexDirection: "column",
  }),
  PeriodTitle: styled(Typography)({
    marginBottom: "4px",
  }),
  PeriodDate: styled(Typography)({}),
  Amount: styled("p")({
    fontSize: "24px",
    fontWeight: 600,
  }),
  BillingList: styled("div")({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "0px 20px 0px 20px",
  }),
  BillingItem: styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0",
  }),
  ItemTitle: styled(Typography)({}),
  ItemAmount: styled(Typography)({}),
  Divider: styled("div")({
    height: "1px",
    backgroundColor: "#E0E0E0",
  }),
};
