import { GetPropDefTypes, PropDef } from "_components/props/prop-def";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import { CSSProperties } from "styled-components";

// TODO: 타입 정리
const active = ["on", "off"] as const;
const size = ["sm", "md"] as const;

type TabOwnProps = GetPropDefTypes<typeof tabPropDefs> & {
  onClick?: (e: any) => void;
  children?: React.ReactNode;
  isLine?: boolean;
  sx?: CSSProperties | undefined;
};

type TabProps = ComponentPropsWithoutRef<"div"> & TabOwnProps;

type StyledTabProps = Pick<TabProps, "active" | "width" | "size" | "isLine">;

const tabPropDefs = {
  width: {
    type: "string",
    default: "",
  },
  active: {
    type: "enum",
    values: active,
    default: "off",
  },
  size: {
    type: "enum",
    values: size,
    default: "md",
  },
  label: {
    type: "string",
    default: "",
  },
  showBadge: {
    type: "ReactNode",
    default: "",
  },
  showNumber: {
    type: "ReactNode",
    default: "",
  },
  endAdornment: {
    type: "ReactNode",
    default: "",
  },
} satisfies {
  width: PropDef<string>;
  active: PropDef<string>;
  size: PropDef<string>;
  label: PropDef<string>;
  showBadge: PropDef<ReactNode>;
  showNumber: PropDef<ReactNode>;
  endAdornment: PropDef<ReactNode>;
};

export { tabPropDefs };
export type { TabProps, StyledTabProps };
