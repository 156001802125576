import React from "react";
import Typography from "_components/Typography";
import styled from "styled-components";

interface TableProps {
  children: React.ReactNode;
}

interface TableHeaderProps {
  children: React.ReactNode;
}

interface TableBodyProps {
  children: React.ReactNode;
}

interface TableRowProps {
  children: React.ReactNode;
}

interface TableHeadProps {
  children: React.ReactNode;
}

interface TableCellProps {
  children: React.ReactNode;
}

const Table: React.FC<TableProps> & {
  Header: React.FC<TableHeaderProps>;
  Body: React.FC<TableBodyProps>;
  Row: React.FC<TableRowProps>;
  Head: React.FC<TableHeadProps>;
  Cell: React.FC<TableCellProps>;
} = ({ children }) => {
  return (
    <S.TableWrapper>
      <S.TableElement>{children}</S.TableElement>
    </S.TableWrapper>
  );
};

Table.Header = ({ children }) => {
  return (
    <S.HeaderWrapper>
      <S.TableElement>
        <S.TableHeader>{children}</S.TableHeader>
      </S.TableElement>
    </S.HeaderWrapper>
  );
};

Table.Body = ({ children }) => {
  return (
    <S.TableElement>
      <S.TableBody>{children}</S.TableBody>
    </S.TableElement>
  );
};

Table.Row = ({ children }) => {
  return <S.TableRow>{children}</S.TableRow>;
};

Table.Head = ({ children }) => {
  return (
    <S.TableHead>
      <Typography variant="body.300" color="text_10">
        {children}
      </Typography>
    </S.TableHead>
  );
};

Table.Cell = ({ children }) => {
  return (
    <S.TableCell>
      <Typography variant="body.300" color="text_10">
        {children}
      </Typography>
    </S.TableCell>
  );
};

const S = {
  TableWrapper: styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "500px", // 전체 높이 설정
    overflow: "hidden", // 외부 스크롤 방지
  }),
  HeaderWrapper: styled("div")({
    position: "sticky",
    top: 0,
    zIndex: 1,
    background: "var(--background_default)",
    borderTop: "1px solid var(--line_10)",
    borderBottom: "1px solid var(--line_10)",
  }),
  TableElement: styled("table")({
    width: "100%",
    textAlign: "left",
    color: "var(--gray-500)",
    borderCollapse: "separate",
    borderSpacing: 0,
    tableLayout: "fixed",
  }),
  TableHeader: styled("thead")({
    color: "var(--gray-700)",
    textTransform: "uppercase",
    backgroundColor: "var(--gray-50)",
  }),
  TableBody: styled("tbody")({
    display: "block",
    overflowY: "auto",
    height: "calc(500px - 50px)",
    backgroundColor: "var(--white)",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E0E0E0",
      borderRadius: "3px",
    },
  }),

  TableRow: styled("tr")({
    display: "table", // 행을 테이블로 표시
    width: "100%", // 전체 너비 사용
    tableLayout: "fixed", // 고정 레이아웃
    "&:not(:last-child)": {
      borderBottom: "1px solid var(--line_10)",
    },
    "&:hover": {
      backgroundColor: "var(--background_hover)",
    },
  }),
  TableHead: styled("th")({
    backgroundColor: "var(--background_default)",
    borderBottom: "1px solid var(--line_10)",
    padding: "12px 24px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  TableCell: styled("td")({
    borderBottom: "1px solid var(--line_10)",
    padding: "12px 24px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
};

export { Table };
