import colorchip from "./colorchip";

const colorToken = {
  white: colorchip.white,
  black: colorchip.black,
  background: {
    default: colorchip.gray_50,
    default2: colorchip.gray_100,
    default3: colorchip.Bgray_100,
    default4: colorchip.Bgray_200,
    default5: colorchip.Bgray_300,
  },
  snackbar: colorchip.Ngray_770,
  backdrop: colorchip.Ngray_900,
  backdrop2: colorchip.Ngray_900,
  conent: "#ECF6FF",

  // text
  text_5: colorchip.gray_900,
  text_10: colorchip.Bgray_700,
  text_15: colorchip.Bgray_600,
  text_20: colorchip.Bgray_500,
  text_30: colorchip.Bgray_300,
  text_40: colorchip.Bgray_200,
  text_50: colorchip.Bgray_100,
  text_60: colorchip.gray_50,

  placeholder: colorchip.Bgray_500,

  // line
  line_10: colorchip.gray_100,
  line_20: colorchip.Bgray_200,
  line_30: colorchip.Bgray_400,
  line_40: colorchip.Bgray_600,
  line_50: colorchip.black,
  line_60: colorchip.black,
  line_70: colorchip.blue_100,
  line_100: colorchip.blue_500,

  // primary
  main: colorchip.blue_500,

  // tab bar
  tab_on: colorchip.blue_500,
  tab_off: colorchip.gray_400,

  // tab box
  tab_box_text_on: colorchip.white,
  tab_box_text_off: colorchip.Bgray_500,
  tab_box_bg: colorchip.blue_500,
  tab_box_line: colorchip.Bgray_500,

  // icon
  icon_default: colorchip.white,
  icon_default2: colorchip.Bgray_100,
  icon_default3: colorchip.Bgray_200,
  icon_default4: colorchip.Bgray_400,
  icon_default5: colorchip.Bgray_500,
  icon_default6: colorchip.Egray_400,
  icon_color: colorchip.blue_500,

  // btn
  btn_bg: colorchip.blue_500,
  btn_bg2: colorchip.blue_500,
  btn_bg3: colorchip.blue_500,
  btn_bg4: colorchip.blue_500,
  btn_bg5: colorchip.blue_500,
  btn_bg6: colorchip.blue_500,
  btn_bg7: colorchip.blue_500,
  btn: "#c2c2c2",
  btn2: "#0000000D",
  btn3: "#0000001A",

  btn_textfiled: colorchip.Egray_200,

  // card
  card_bg1: colorchip.Egray_500,
  card_bg2: colorchip.Ngray_800,
  card_bg_up: colorchip.Ngray_770,

  // system
  system_error: colorchip.red_400,
  system_error2: colorchip.red_550,
  system_info: colorchip.blue_450,
  system_success: colorchip.green_500,
  system_warning: colorchip.yellow_400,

  // primary
  primary_main: colorchip.blue_500,
  primary_main_32dp: colorchip.blue_430_32,
  primary_main_20dp: colorchip.blue_430_20,
  primary_main_15dp: colorchip.blue_430_15,
  primary_main_10dp: colorchip.blue_430_10,
  primary_main_5dp: colorchip.blue_430_5,

  ...colorchip,
};

export default colorToken;
