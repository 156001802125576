/* eslint-disable no-unused-vars */
import { Doughnut } from "react-chartjs-2";
import Loading from "_components/Loading";
import Typography from "_components/Typography";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import styled from "styled-components";

ChartJS.register(ArcElement, Tooltip, Legend);

const mockAlarms = {
  data: {
    MetricAlarms: [
      {
        AlarmName: "CPU 사용률 경고",
        AlarmDescription: "CPU 사용률이 90%를 초과했습니다.",
        severity: "high",
      },
      {
        AlarmName: "메모리 부족",
        AlarmDescription: "가용 메모리가 10% 미만입니다.",
        severity: "high",
      },
      {
        AlarmName: "디스크 공간 부족",
        AlarmDescription: "디스크 사용량이 85%를 초과했습니다.",
        severity: "medium",
      },
      {
        AlarmName: "네트워크 지연",
        AlarmDescription: "평균 응답 시간이 2초를 초과했습니다.",
        severity: "medium",
      },
      {
        AlarmName: "데이터베이스 연결 실패",
        AlarmDescription:
          "데이터베이스 연결 시도 실패가 5회 이상 발생했습니다.",
        severity: "high",
      },
      {
        AlarmName: "API 오류율 증가",
        AlarmDescription: "API 오류율이 5%를 초과했습니다.",
        severity: "medium",
      },
      {
        AlarmName: "보안 그룹 변경",
        AlarmDescription: "보안 그룹 설정이 변경되었습니다.",
        severity: "low",
      },
      {
        AlarmName: "백업 실패",
        AlarmDescription: "정기 백업 작업이 실패했습니다.",
        severity: "high",
      },
      {
        AlarmName: "SSL 인증서 만료 임박",
        AlarmDescription: "SSL 인증서가 30일 이내에 만료됩니다.",
        severity: "low",
      },
      {
        AlarmName: "로그인 시도 실패",
        AlarmDescription: "동일 IP에서 다수의 로그인 실패가 감지되었습니다.",
        severity: "medium",
      },
      {
        AlarmName: "서버 재시작",
        AlarmDescription: "예기치 않은 서버 재시작이 발생했습니다.",
        severity: "high",
      },
      {
        AlarmName: "트래픽 급증",
        AlarmDescription: "비정상적인 트래픽 증가가 감지되었습니다.",
        severity: "medium",
      },
    ],
  },
};

const IssuesByRiskCard = () => {
  // const {
  //   data: alarms,
  //   mutate: mutateAlarms,
  //   isLoading: isLoadingAlarms,
  //   isError: isErrorAlarms,
  // }: any = useDescribeAlarms();

  const alarms = mockAlarms;
  const isLoadingAlarms = false;

  // 위험도별 이슈 수 계산 (severity가 없으면 critical로 처리)
  const issueCountsBySeverity: any =
    alarms?.data?.MetricAlarms?.reduce((acc, alarm) => {
      const severity = alarm.severity || "critical";
      acc[severity] = (acc[severity] || 0) + 1;
      return acc;
    }, {}) || {};

  if (isLoadingAlarms) {
    return <Loading.Chart />;
  }

  const totalIssues = alarms?.data?.MetricAlarms?.length;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const data = {
    labels: ["Critical", "High", "Medium", "Low", "None"],
    datasets: [
      {
        data: [
          issueCountsBySeverity?.critical || 0,
          issueCountsBySeverity?.high || 0,
          issueCountsBySeverity?.medium || 0,
          issueCountsBySeverity?.low || 0,
          totalIssues -
            (issueCountsBySeverity?.critical || 0) -
            (issueCountsBySeverity?.high || 0) -
            (issueCountsBySeverity?.medium || 0) -
            (issueCountsBySeverity?.low || 0),
        ],
        backgroundColor: [
          "#FF4D4F",
          "#FFA940",
          "#FADB14",
          "#52C41A",
          "#D9D9D9",
        ],
      },
    ],
  };
  return (
    <S.Container>
      <S.Title variant="subtitle.200_sb" color="text_5">
        Issues by Risk
      </S.Title>
      <S.Content>
        <S.ChartContainer>
          <Doughnut data={data} options={options} />
          <S.CenterText>
            <S.TotalContainer>
              <S.TotalIssues variant="subtitle.100_sb" color="text_5">
                {totalIssues}
              </S.TotalIssues>
              <S.SubText variant="body.300" color="text_5">
                All risk
              </S.SubText>
            </S.TotalContainer>
          </S.CenterText>
        </S.ChartContainer>

        <S.LegendContainer>
          {data.labels.map((label, index) => (
            <S.LegendItem key={index}>
              <S.LegendColor color={data.datasets[0].backgroundColor[index]} />
              <S.LegendText variant="body.300" color="text_20">
                {label}
              </S.LegendText>
              <S.LegendValue
                variant="body.300_sb"
                color={label === "critical" ? "#FF4D4F" : ""}
              >
                {data.datasets[0].data[index]}
              </S.LegendValue>
            </S.LegendItem>
          ))}
        </S.LegendContainer>
      </S.Content>
    </S.Container>
  );
};

export default IssuesByRiskCard;

const S = {
  Container: styled.div({
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),
  Title: styled(Typography)({
    marginBottom: "16px",
  }),
  Content: styled.div({
    display: "flex",
    flex: 1,
  }),
  ChartContainer: styled.div({
    position: "relative",
    width: "50%",
    height: "200px",
  }),
  CenterText: styled.div({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }),
  TotalContainer: styled.div({
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
  TotalIssues: styled(Typography)({}),
  SubText: styled(Typography)({}),
  LegendContainer: styled.div({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "50%",
    paddingLeft: "16px",
  }),
  LegendItem: styled.div({
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  }),
  LegendColor: styled.div<{ color: any }>(({ color }) => ({
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: color,
    marginRight: "8px",
  })),
  LegendText: styled(Typography)({
    marginRight: "8px",
    flex: 1,
  }),
  LegendValue: styled(Typography)<{ color: any }>(({ color }) => ({
    marginRight: "8px",
    color: color,
  })),
};
