import { ColorTokens } from "_types/colors";

import { PropDef } from "./prop-def";

const tokens = [
  "white",
  "black",
  "gray_950",
  "gray_900",
  "gray_800",
  "gray_700",
  "gray_600",
  "gray_500",
  "gray_400",
  "gray_300",
  "gray_200",
  "gray_100",
  "gray_50",
  "gray_25",

  "blue_25",
  "blue_50",
  "blue_100",
  "blue_200",
  "blue_300",
  "blue_400",
  "blue_430",
  "blue_450",
  "blue_500",
  "blue_600",
  "blue_700",
  "blue_800",
  "blue_900",
  "blue_950",

  "green_950",
  "green_900",
  "green_800",
  "green_700",
  "green_600",
  "green_500",
  "green_400",
  "green_300",
  "green_200",
  "green_100",
  "green_50",
  "green_25",

  "yellow_950",
  "yellow_900",
  "yellow_800",
  "yellow_700",
  "yellow_600",
  "yellow_500",
  "yellow_400",
  "yellow_300",
  "yellow_200",
  "yellow_100",
  "yellow_50",
  "yellow_25",

  "red_950",
  "red_900",
  "red_800",
  "red_700",
  "red_600",
  "red_500",
  "red_400",
  "red_300",
  "red_200",
  "red_100",
  "red_50",
  "red_25",

  "Bgray_900",
  "Bgray_800",
  "Bgray_700",
  "Bgray_600",
  "Bgray_500",
  "Bgray_400",
  "Bgray_300",
  "Bgray_200",
  "Bgray_100",

  "Ngray_770",
  "Ngray_800",
  "Ngray_900",

  "Egray_200",
  "Egray_300",
  "Egray_400",
  "Egray_500",

  "blue_430_32",
  "blue_430_20",
  "blue_430_15",
  "blue_430_10",
  "blue_430_5",
  "background",
  "snackbar",
  "backdrop",
  "backdrop2",
  "conent",
  "text_5",
  "text_10",
  "text_15",
  "text_20",
  "text_30",
  "text_40",
  "text_50",
  "text_60",
  "placeholder",
  "line_10",
  "line_20",
  "line_30",
  "line_40",
  "line_50",
  "line_60",
  "line_70",
  "line_100",
  "main",
  "tab_on",
  "tab_off",
  "tab_box_text_on",
  "tab_box_text_off",
  "tab_box_bg",
  "tab_box_line",
  "icon_default",
  "icon_default2",
  "icon_default3",
  "icon_default4",
  "icon_default5",
  "icon_default6",
  "icon_color",
  "btn_bg",
  "btn_bg2",
  "btn_bg3",
  "btn_bg4",
  "btn_bg5",
  "btn_bg6",
  "btn_bg7",
  "btn",
  "btn2",
  "btn3",
  "btn_textfiled",
  "card_bg1",
  "card_bg2",
  "card_bg_up",
  "system_error",
  "system_info",
  "system_success",
  "system_warning",
  "primary_main",
  "primary_main_32dp",
  "primary_main_20dp",
  "primary_main_15dp",
  "primary_main_10dp",
  "primary_main_5dp",
] as const;

const colorPropDef = {
  color: { type: "enum", values: tokens },
} satisfies {
  color: PropDef<ColorTokens>;
};

export { colorPropDef };
