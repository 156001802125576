/* eslint-disable no-unused-vars */
import { Bar } from "react-chartjs-2";
import { Table } from "_components/Dashboard/atoms/Table";
import Loading from "_components/Loading";
import Typography from "_components/Typography";
import { useEC2InstancesInfo } from "_libs/swr/dashboard/dashboard";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import styled from "styled-components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const options = {
  indexAxis: "y" as const,
  elements: {},
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      stacked: true,
      display: false,
    },
    y: {
      stacked: true,
      display: false,
    },
  },
  maintainAspectRatio: false,
};

const ResourceStatusCard = () => {
  const mockInstances = [
    {
      InstanceId: "i-1234567890abcdef0",
      State: "running",
      Tags: { Name: "Web Server 1" },
      CPU: { vCPUs: 2 },
      Memory: { SizeInGiB: 4 },
      Storage: { Devices: [{ SizeInGiB: 100 }] },
    },
    {
      InstanceId: "i-1234567890abcdef1",
      State: "stopped",
      Tags: { Name: "Database Server" },
      CPU: { vCPUs: 4 },
      Memory: { SizeInGiB: 16 },
      Storage: { Devices: [{ SizeInGiB: 500 }] },
    },
    {
      InstanceId: "i-1234567890abcdef2",
      State: "running",
      Tags: { Name: "Application Server" },
      CPU: { vCPUs: 8 },
      Memory: { SizeInGiB: 32 },
      Storage: { Devices: [{ SizeInGiB: 1000 }] },
    },
    {
      InstanceId: "i-1234567890abcdef3",
      State: "pending",
      Tags: { Name: "Test Server" },
      CPU: { vCPUs: 2 },
      Memory: { SizeInGiB: 8 },
      Storage: { Devices: [{ SizeInGiB: 200 }] },
    },
    {
      InstanceId: "i-1234567890abcdef4",
      State: "running",
      Tags: { Name: "Load Balancer" },
      CPU: { vCPUs: 2 },
      Memory: { SizeInGiB: 4 },
      Storage: { Devices: [{ SizeInGiB: 50 }] },
    },
    {
      InstanceId: "i-1234567890abcdef5",
      State: "stopped",
      Tags: { Name: "Backup Server" },
      CPU: { vCPUs: 4 },
      Memory: { SizeInGiB: 16 },
      Storage: { Devices: [{ SizeInGiB: 2000 }] },
    },
    {
      InstanceId: "i-1234567890abcdef6",
      State: "running",
      Tags: { Name: "Monitoring Server" },
      CPU: { vCPUs: 2 },
      Memory: { SizeInGiB: 8 },
      Storage: { Devices: [{ SizeInGiB: 300 }] },
    },
    {
      InstanceId: "i-1234567890abcdef7",
      State: "stopping",
      Tags: { Name: "Dev Environment" },
      CPU: { vCPUs: 4 },
      Memory: { SizeInGiB: 16 },
      Storage: { Devices: [{ SizeInGiB: 500 }] },
    },
    {
      InstanceId: "i-1234567890abcdef8",
      State: "running",
      Tags: { Name: "Prod Environment" },
      CPU: { vCPUs: 16 },
      Memory: { SizeInGiB: 64 },
      Storage: { Devices: [{ SizeInGiB: 2000 }] },
    },
    {
      InstanceId: "i-1234567890abcdef9",
      State: "terminated",
      Tags: { Name: "Old Server" },
      CPU: { vCPUs: 2 },
      Memory: { SizeInGiB: 4 },
      Storage: { Devices: [{ SizeInGiB: 100 }] },
    },
    {
      InstanceId: "i-1234567890abcdefa",
      State: "running",
      Tags: { Name: "API Server" },
      CPU: { vCPUs: 4 },
      Memory: { SizeInGiB: 16 },
      Storage: { Devices: [{ SizeInGiB: 300 }] },
    },
    {
      InstanceId: "i-1234567890abcdefb",
      State: "stopped",
      Tags: { Name: "Staging Server" },
      CPU: { vCPUs: 2 },
      Memory: { SizeInGiB: 8 },
      Storage: { Devices: [{ SizeInGiB: 200 }] },
    },
  ];

  // useEC2InstancesInfo 훅 대신 목업 데이터 사용
  const instances = mockInstances;
  const isLoading = false;
  const isError = false;

  // 인스턴스 상태에 따른 이모지 매핑
  const stateEmoji = {
    running: "🟢",
    stopped: "🔴",
    pending: "🟡",
    stopping: "🟠",
    terminated: "⚪",
  };

  // 차트 데이터 계산
  const chartData = {
    running: 0,
    stopped: 0,
    other: 0,
  };

  instances?.forEach((instance) => {
    if (instance.State === "running") chartData.running++;
    else if (instance.State === "stopped") chartData.stopped++;
    else chartData.other++;
  });

  const data = {
    labels: [`Total ${instances ? instances.length : 0}`],
    datasets: [
      {
        label: "중지",
        data: [chartData.stopped],
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "실행",
        data: [chartData.running],
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "기타",
        data: [chartData.other],
        backgroundColor: "rgb(201, 203, 207)",
      },
    ],
  };
  return (
    <S.Container>
      <S.Header>
        <S.Title variant="subtitle.200_sb" color="text_5">
          Resource Status
        </S.Title>

        <S.Select>
          <option>
            <Typography variant="body.301" color="text_10">
              VM
            </Typography>
          </option>
        </S.Select>
      </S.Header>

      <S.ChartContainer>
        <S.TotalContainer>
          <S.TotalText variant="body.100" color="text_10">
            Total
          </S.TotalText>

          <S.TotalNumber variant="body.100_sb" color="text_5">
            {instances?.length || 0}
          </S.TotalNumber>
        </S.TotalContainer>

        <S.BarChartWrapper>
          <Bar options={options} data={data} />
        </S.BarChartWrapper>

        <S.LegendContainer>
          <S.LegendItem>
            <S.LegendColor color="rgb(255, 99, 132)" />
            <Typography variant="body.300" color="text_20">
              중지
            </Typography>
            <Typography variant="body.300_sb" color="text_5">
              {chartData.stopped}
            </Typography>
          </S.LegendItem>

          <S.LegendItem>
            <S.LegendColor color="rgb(75, 192, 192)" />
            <Typography variant="body.300" color="text_20">
              실행
            </Typography>
            <Typography variant="body.300_sb" color="text_5">
              {chartData.running}
            </Typography>
          </S.LegendItem>

          <S.LegendItem>
            <S.LegendColor color="rgb(201, 203, 207)" />
            <Typography variant="body.300" color="text_20">
              기타
            </Typography>
            <Typography variant="body.300_sb" color="text_5">
              {chartData.other}
            </Typography>
          </S.LegendItem>
        </S.LegendContainer>
      </S.ChartContainer>

      {/* Table */}
      <Table.Header>
        <Table.Row>
          <Table.Head>State</Table.Head>
          <Table.Head>Server Name</Table.Head>
          <Table.Head>vCore</Table.Head>
          <Table.Head>Memory</Table.Head>
          <Table.Head>Disk</Table.Head>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {instances?.map((instance, index) => (
          <Table.Row key={instance.InstanceId}>
            <Table.Cell>{stateEmoji[instance.State] || "-"}</Table.Cell>
            <Table.Cell>{instance.Tags?.Name || "N/A"}</Table.Cell>
            <Table.Cell>{instance.CPU?.vCPUs || "N/A"}</Table.Cell>
            <Table.Cell>{instance.Memory?.SizeInGiB || "N/A"} GB</Table.Cell>
            <Table.Cell>
              {instance.Storage?.Devices?.[0]?.SizeInGiB || "N/A"} GB
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </S.Container>
  );
};

export default ResourceStatusCard;

const S = {
  Container: styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "100%",
  }),
  Header: styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  }),
  Title: styled(Typography)({}),
  TotalNumber: styled(Typography)({}),
  Select: styled("select")({
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    padding: "4px 8px",
  }),
  ChartContainer: styled("div")({
    marginBottom: "32px",
  }),
  TotalText: styled(Typography)({
    marginRight: "7.5px",
  }),
  BarChartWrapper: styled("div")({
    height: "22px",
    marginBottom: "4px",
  }),
  LegendContainer: styled("div")({
    display: "flex",
    gap: "20px",
  }),
  LegendItem: styled("div")({
    display: "flex",
    alignItems: "center",
    gap: "9px",
  }),
  LegendColor: styled("div")<{ color: string }>(({ color }) => ({
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: color,
    marginRight: "4px",
  })),
  TableContainer: styled("div")({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "hidden",
  }),
  TotalContainer: styled("div")({
    display: "flex",
    alignItems: "center",
    marginBottom: "4px",
  }),
};
