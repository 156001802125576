"use client";
import React, { forwardRef } from "react";
import typography from "_styles/typography";
import styled from "styled-components";

import { buttonPropDefs, ButtonProps, StyledButtonProps } from "./types";

// TODO : static 객체 정리
const getSize = {
  "2xs": {
    padding: "6px 8px",
    height: "24px",
  },
  xs: {
    padding: "6px 8px",
    height: "28px",
  },
  sm: {
    padding: "6px 8px",
    height: "34px",
  },
  md: {
    padding: "9px 12px",
    height: "40px",
  },
  lg: {
    padding: "9px 12px",
    height: "48px",
  },
  xl: {
    padding: "9px 12px",
    height: "52px",
  },
  "2xl": {
    padding: "9px 12px",
    height: "58px",
  },
};

function getVariant(props) {
  const { variant, disabled, selected } = props;

  switch (variant) {
    case "default":
      return {
        backgroundColor: `var(--${disabled ? "gray_100" : "primary_main"})`,
        color: `var(--${disabled ? "text_40" : "text_60"})`,
        border: "none",
        ":hover": {
          backgroundColor: `var(--${disabled ? "" : "blue_400"})`,
        },
      };
    case "line":
      return {
        border: `1px solid var(--${disabled ? "line_10" : "primary_main"})`,
        backgroundColor: `var(--${disabled ? "" : "white"})`,
        color: `var(--${disabled ? "text_30" : "primary_main"})`,
        ":hover": {
          backgroundColor: "#409CFF2E",
        },
      };
    case "gray1":
      return {
        background: "var(--btn_bg6)",
        border: "none",
        color: "var(--white)",
      };
    case "gray2":
      return {
        background: "var(--btn2)",
        border: "none",
        color: "var(--text_10)",
      };
    case "grayline":
      return {
        border: `1px solid var(--${disabled ? "line_10" : selected ? "primary_main" : "btn3"})`,
        background: "none",
        color: `var(--${disabled ? "text_30" : selected ? "primary_main" : "text_10"})`,
      };
    case "label":
      return {
        border: "none",
        background: "none",
        color: "var(--text_10)",
      };
  }
}

const getLabel = {
  "2xs": typography["caption.100"],
  xs: typography["caption.100"],
  sm: typography["body.100"],
  md: typography["body.200"],
  lg: typography["body.200"],
  xl: typography["body.200"],
  "2xl": typography["body.200"],
};

/**
 *  @example <Button variant="gray1" size="md">button</Button>
 *
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    width = buttonPropDefs.width.default,
    size = buttonPropDefs.size.default,
    variant = buttonPropDefs.variant.default,
    disabled = props.loading || buttonPropDefs.disabled.default,
    startIcon,
    endIcon,
    selected = buttonPropDefs.selected.default,
    // color,
    ...buttonProps
  } = props;

  return (
    <S.Button
      {...buttonProps}
      ref={ref}
      disabled={disabled}
      size={size}
      variant={variant}
      width={width}
      selected={selected}
    >
      {startIcon && startIcon}

      <S.Label size={size}>{children}</S.Label>

      {endIcon && endIcon}
    </S.Button>
  );
});

const S = {
  Button: styled("button")((props: StyledButtonProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    borderRadius: "4px",
    transition: "all .25s",
    cursor: props.disabled ? "not-allowed" : "pointer",
    width: props.width,
    ...getSize[props.size],
    ...getVariant(props),
  })),
  Label: styled("span")(({ size }: Pick<ButtonProps, "size">) => ({
    ...getLabel[size],
  })),
};

Button.displayName = "Button";

export { Button };
