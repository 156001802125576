import { colorPropDef } from "_components/props/color-def";
import { variantPropDef } from "_components/props/variant-def";

const as = ["span", "div", "label", "p"] as const;

const typographyPropDefs = {
  as: { type: "enum", values: as, default: "span" },
  ...variantPropDef,
  ...colorPropDef,
};

export { typographyPropDefs };
