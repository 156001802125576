import styled from "styled-components";

import { SVGProps } from "./types";

/**
 * @param fill - 이미지 채움 색상
 * @param stroke - 이미지 외곽선 색상
 * @param strokeWidth - 외곽선 두께
 *
 * @remarks
 * fill or stroke를 지정해야함!!
 *
 * @example
 * <SVG src={Bottom} fill="black" stroke="red" strokeWidth={1} width={100} height={100} />
 */

const SVG = (props: SVGProps) => {
  const {
    fill,
    stroke,
    strokeWidth,
    width,
    height,
    src: SvgComponent,
    style,
    onClick,
    hover,
  } = props;

  return (
    <S.SVG
      fill={fill || "none"} // 기본값이 없으면 current으로 설정
      stroke={stroke || "none"} // 기본값이 없으면 none으로 설정
      strokeWidth={strokeWidth}
      width={width}
      height={height}
      style={style}
      onClick={onClick}
      as={SvgComponent}
      hover={hover}
    />
  );
};

export default SVG;

const S = {
  SVG: styled("svg")(
    ({ fill, stroke, strokeWidth, width, height, hover }: SVGProps) => ({
      strokeWidth: strokeWidth,
      width: width,
      height: height,
      path: {
        fill: fill,
        stroke: stroke,
      },
      "&:hover": {
        path: {
          stroke: hover?.stroke,
          fill: hover?.fill,
        },
      },
    }),
  ),
};
