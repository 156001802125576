import { Line } from "./Line";
import { Text } from "./Text";

/**
 *  @example
 * <Tab.Line active="on" width="50px" onClick={() => handleChangeTab(index)} showBadge>tab</Tab.Line>
 * <Tab.Text active="on" width="50px" onClick={() => handleChangeTab(index)} showNumber>NumberTab</Tab.Text>
 */

const Tab = () => {
  //
};

Tab.Line = Line;
Tab.Text = Text;

export default Tab;
