import {
  ComponentPropsWithoutRef,
  CSSProperties,
  ElementRef,
  forwardRef,
} from "react";
import { GetPropDefTypes } from "_components/props/prop-def";
import colorToken from "_styles/colorToken";
import typography from "_styles/typography";
import styled from "styled-components";

import { typographyPropDefs } from "./types";

type TypohraphyElement = ElementRef<"span">;
type TypographySpanProps = { as?: "span" } & ComponentPropsWithoutRef<"span">;
type TypohraphyOwnProps = GetPropDefTypes<typeof typographyPropDefs>;
type TypohraphyProps = TypographySpanProps & TypohraphyOwnProps & CSSProperties;

/**
 * @param {string} variant - 디자인 시스템에 적용된 variant 참고
 * @param {string} color - 디자인 시스템에 적용된 colorToken 참고
 * @example
 * <Typography variant='' color=''></Typography>
 */
const Typography = forwardRef<TypohraphyElement, TypohraphyProps>(
  (props, forwardedRef) => {
    const {
      as = "span",
      variant = typographyPropDefs.variant.default,
      color,
      children,
      style,
      className,
      // ...typoProps
    } = props;

    return (
      <S.Typography
        ref={forwardedRef}
        color={color}
        variant={variant}
        style={style}
        className={className}
        // {...typoProps}
      >
        {children}
      </S.Typography>
    );
  },
);

type TypographyStyleProps = {
  color: any;
  variant: any;
};

const S = {
  Typography: styled("span")(({ color, variant }: TypographyStyleProps) => ({
    color: colorToken[color],
    ...typography[variant],
  })),
};

Typography.displayName = "Typography";

export default Typography;
