const colorchip = {
  white: "#fff",
  black: "#000",

  gray_950: "#1A1B1D",
  gray_900: "#242628",
  gray_800: "#333539",
  gray_700: "#4C4F54",
  gray_600: "#626467",
  gray_500: "#7E8185",
  gray_400: "#AAADB3",
  gray_300: "#D4D6D9",
  gray_200: "#EBEDF0",
  gray_100: "#F2F3F5",
  gray_50: "#FAFAFA",
  gray_25: "#FCFCFD",

  blue_25: "#F5FAFF",
  blue_50: "#EFF8FF",
  blue_100: "#D1E9FF",
  blue_200: "#B2DDFF",
  blue_300: "#84CAFF",
  blue_400: "#53B1FD",
  blue_430: "#409CFF",
  blue_450: "#4198FF",
  blue_500: "#2E90FA",
  blue_600: "#1570EF",
  blue_700: "#175CD3",
  blue_800: "#1849A9",
  blue_900: "#194185",
  blue_950: "#102A56",

  green_950: "#053321",
  green_900: "#054F31",
  green_800: "#05603A",
  green_700: "#027A48",
  green_600: "#039855",
  green_500: "#12B76A",
  green_400: "#32D583",
  green_300: "#6CE9A6",
  green_200: "#A6F4C5",
  green_100: "#D1FADF",
  green_50: "#ECFDF3",
  green_25: "#F6FEF9",

  yellow_950: "#4E1D09",
  yellow_900: "#7A2E0E",
  yellow_800: "#93370D",
  yellow_700: "#B54708",
  yellow_600: "#DC6803",
  yellow_500: "#F79009",
  yellow_400: "#FFA800",
  yellow_300: "#FEC84B",
  yellow_200: "#FEDF89",
  yellow_100: "#FEF0C7",
  yellow_50: "#FFFAEB",
  yellow_25: "#FFFCF5",

  red_950: "#55160C",
  red_900: "#7A271A",
  red_800: "#912018",
  red_700: "#B42318",
  red_600: "#D92D20",
  red_500: "#F04438",
  red_550: "#F63B42",
  red_400: "#FF5F55",
  red_300: "#FDA29B",
  red_200: "#FECDCA",
  red_100: "#FEE4E2",
  red_50: "#FEF3F2",
  red_25: "#FFFBFA",

  Bgray_900: "#1D242D",
  Bgray_800: "#2D3742",
  Bgray_700: "#414B56",
  Bgray_600: "#5C6978",
  Bgray_500: "#708193",
  Bgray_400: "#8E9CAB",
  Bgray_300: "#A6B3C0",
  Bgray_200: "#C1C7D1",
  Bgray_100: "#EAECF0",

  Ngray_770: "#363A47",
  Ngray_800: "#292A33",
  Ngray_900: "#1F2229",

  Egray_200: "#3E414B",
  Egray_300: "#343740",
  Egray_400: "#292B33",
  Egray_500: "#1F2026",

  blue_430_32: "rgba(64, 156, 255, 0.32))",
  blue_430_20: "rgba(64, 156, 255, 0.20))",
  blue_430_15: "rgba(64, 156, 255, 0.15))",
  blue_430_10: "rgba(64, 156, 255, 0.10))",
  blue_430_5: "rgba(64, 156, 255, 0.05)",
};

export default colorchip;
