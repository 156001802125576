import { forwardRef } from "react";
import typography from "_styles/typography";
import styled from "styled-components";

import { StyledTabProps, tabPropDefs, TabProps } from "./types";

const getVariant = (type: string) => {
  switch (type) {
    case "on":
      return {
        color: "var(--text_5)",
        labelColor: "var(--text_60)",
        bgColor: "var(--blue_500)",
      };
    case "off":
      return {
        color: "var(--text_40)",
        labelColor: "var(--text_40)",
        bgColor: "var(--background_default2)",
      };
    default:
      return {};
  }
};

const Text = forwardRef<HTMLDivElement, TabProps>((props, ref) => {
  const {
    children,
    onClick,
    active,
    width = tabPropDefs.width,
    label,
    showNumber,
  } = props;

  const variant = getVariant(active);

  return (
    <S.Tab
      ref={ref}
      onClick={onClick}
      active={active}
      width={width}
      variant={variant}
    >
      <S.TabItem>{children}</S.TabItem>
      {showNumber && (
        <S.Badge variant={variant}>
          <S.Label variant={variant}>{label}</S.Label>
        </S.Badge>
      )}
    </S.Tab>
  );
});

const S = {
  Tab: styled("div")((props: StyledTabProps) => ({
    padding: "12px 8px",
    width: props.width,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
    ...getVariant(props.active),
    "&:hover": {
      [`${S.TabItem}`]: {
        color: "var(--text_20)",
      },
      [`${S.Label}`]: {
        color: "var(--text_20)",
      },
    },
  })),
  TabItem: styled("span")(() => ({
    ...typography["body.100_sb"],
    transition: "color 0.2s ease",
  })),
  Badge: styled("div")(({ variant }: any) => ({
    background: variant.bgColor,
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "999px",
    padding: "0px 6px",
  })),
  Label: styled("span")(({ variant }: any) => ({
    ...typography["body.300_sb"],
    color: variant.labelColor,
    transition: "color 0.2s ease",
    marginTop: "2px",
  })),
};

Text.displayName = "Text";

export { Text };
