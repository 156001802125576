import { breakpoints } from "_styles/theme";
import styled from "styled-components";

interface GridContainerProps {
  md?: number;
  lg?: number;
  xl?: number;
  margin?: string;
  maxWidth?: typeof breakpoints;
}

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  margin: ${({ margin }) => margin};

  @media (max-width: 1920px) {
    grid-template-columns: repeat(12, 1fr);
    max-width: 1740px;
  }
  @media (max-width: 1280px) {
    grid-template-columns: repeat(8, 1fr);
    max-width: 960px;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(8, 1fr);
    max-width: 704px;
  }
`;

GridContainer.displayName = "Grid.Container";

interface GridItemProps {
  md?: number;
  lg?: number;
  xl?: number;
  container?: boolean;
  align?: "start" | "center" | "end";
}

const GridItem = styled.div<GridItemProps>`
  grid-column: ${({ xl, lg, md }) => `span ${xl || lg || md || 12}`};
  grid-auto-rows: min-content;
  margin: ${(props) => props.align === "center" && "0 auto"};

  ${(props) =>
    props.container &&
    `
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 20px;

      @media (max-width: 1920px) {
        grid-template-columns: repeat(12, 1fr);
        max-width: 1740px;
      }
      @media (max-width: 1280px) {
        grid-template-columns: repeat(8, 1fr);
        max-width: 960px;
      }
      @media (max-width: 768px) {
        grid-template-columns: repeat(8, 1fr);
        max-width: 704px;
      }
  `}
`;

GridItem.displayName = "Grid.GridItem";

export { GridContainer as Container, GridItem as Item };
