import { ComponentPropsWithoutRef, ReactNode } from "react";

import { GetPropDefTypes, PropDef } from "../props/prop-def";

const sizes = ["2xs", "xs", "sm", "md", "lg", "xl", "2xl"] as const;
const variants = [
  "default",
  "line",
  "gray1",
  "gray2",
  "grayline",
  "label",
] as const;
const disabledVariants = ["default", "line", "grayline", "label"] as const;

type Variants = (typeof variants)[number];
type DisabledVariants = (typeof disabledVariants)[number];

type Variant = Extract<Variants, DisabledVariants>;

type ButtonOwnProps =
  | {
      variant: "gray1" | "gray2";
      disabled?: never;
    }
  | {
      variant: Variant;
      disabled?: boolean;
    }
  | {
      variant: "grayline";
      selected?: boolean;
    };

type ButtonBaseProps = GetPropDefTypes<typeof buttonPropDefs> & {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
};

type ButtonProps = ComponentPropsWithoutRef<"button"> &
  ButtonBaseProps &
  ButtonOwnProps;

type StyledButtonProps = Pick<
  ButtonProps,
  "size" | "disabled" | "variant" | "width" | "selected"
>;

const buttonPropDefs = {
  width: {
    type: "string",
    default: "",
  },
  size: {
    type: "enum",
    values: sizes,
    default: "xs",
    // responsive: true,
  },
  variant: {
    type: "enum",
    values: variants,
    default: "default",
  },
  loading: { type: "boolean", default: false },
  disabled: { type: "boolean", default: false },
  selected: { type: "boolean", default: false },
} satisfies {
  width: PropDef<string>;
  size: PropDef<(typeof sizes)[number]>;
  variant: PropDef<(typeof variants)[number]>;
  loading: PropDef<boolean>;
  disabled: PropDef<boolean>;
  selected: PropDef<boolean>;
};

export { buttonPropDefs };
export type {
  ButtonProps,
  ButtonBaseProps,
  ButtonOwnProps,
  Variants,
  DisabledVariants,
  StyledButtonProps,
};
