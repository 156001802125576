import { forwardRef } from "react";
import typography from "_styles/typography";
import styled from "styled-components";

import { StyledTabProps, TabProps } from "./types";

const getVariant = (type: any) => {
  switch (type) {
    case "on":
      return {
        borderBottom: "2px solid #1F2026",
        color: "var(--text_5)",
      };
    case "off":
      return {
        color: "var(--text_20)",
      };
    default:
      return {};
  }
};

const Line = forwardRef<HTMLDivElement, TabProps>((props, ref) => {
  const {
    children,
    onClick,
    active,
    width,
    endAdornment,
    size,
    isLine = true,
  } = props;

  return (
    <S.Tab
      ref={ref}
      onClick={onClick}
      active={active}
      width={width}
      isLine={isLine}
    >
      <S.TabItem size={size}>{children}</S.TabItem>
      {endAdornment && <div>{endAdornment}</div>}
    </S.Tab>
  );
});

Line.displayName = "Line";

export { Line };

const S = {
  Tab: styled("div")((props: StyledTabProps) => ({
    padding: "12px 8px",
    width: props.width,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
    ...(props.isLine ? getVariant(props.active) : {}),
    transition: "color 0.2s ease",
  })),
  TabItem: styled("span")(({ active, size }: StyledTabProps) => ({
    ...(size === "sm"
      ? typography["body.200_sb"]
      : typography["subtitle.200_sb"]),
    ...getVariant(active),
    transition: "color 0.1s ease",
  })),
  Badge: styled("div")(() => ({
    background: "var(--background_default2)",
    height: "20px",
    padding: "0px 6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "999px",
  })),
};
